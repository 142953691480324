import { createStyles, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import React, { CSSProperties, FC } from "react";

const useStyles = makeStyles(muiTheme =>
    createStyles({
        container: {
            position: "relative",
            // bottom: 0,
            width: "100%",
            maxWidth: "800px",
            padding: muiTheme.spacing(2)
        },
        copyright: {
            fontSize: "12px",
            color: ({ dark }: { dark: boolean }) => (dark ? "#fff" : "#8898AA")
        },
        link: {
            fontSize: "12px",
            color: ({ dark }: { dark: boolean }) => (dark ? "#fff" : "#8898AA"),
            padding: muiTheme.spacing(0.5, 0)
        }
    })
);

interface RelativeFooterProps {
    dark?: boolean;
}

export const RelativeFooter: FC<RelativeFooterProps> = ({ dark = false }) => {
    const classes = useStyles({ dark: false });
    return (
        <Grid container justifyContent={"center"} alignItems={"center"} item xs={12}>
            <Grid container justifyContent={"center"} item xs={12} md={3}>
                <Typography className={classes.copyright}>
                    © {new Date().getFullYear()}
                    <a target={"_blank"} style={{ fontWeight: 600, marginLeft: 4 }} href={"https://www.onpreo.com"}>
                        onpreo AG
                    </a>
                </Typography>
            </Grid>
            <Grid container justifyContent={"center"} item xs={12} md={3}>
                <a target={"_blank"} href={"https://www.onpreo.com/terms-of-service"} className={classes.link}>
                    <Typography className={classes.link}>Impressum</Typography>
                </a>
            </Grid>
            <Grid container justifyContent={"center"} item xs={12} md={3}>
                <a target={"_blank"} href={"https://www.onpreo.com/agbs"} className={classes.link}>
                    <Typography className={classes.link}>AGB</Typography>
                </a>
            </Grid>
            <Grid container justifyContent={"center"} item xs={12} md={3}>
                <a target={"_blank"} href={"https://www.onpreo.com/privacy-policy"} className={classes.link}>
                    <Typography className={classes.link}>Datenschutz</Typography>
                </a>
            </Grid>
        </Grid>
    );
};
