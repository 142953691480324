import { Fragment } from "react";
import Script from "next/script";

const RewardfulHelper = () => {
    const isDev = process.env.NODE_ENV === "development";
    if (isDev) return <script id="no-referall-script" />;
    return (
        <Fragment>
            <Script src="https://r.wdfl.co/rw.js" data-rewardful="4691ca"></Script>
            <Script id="rewardful-queue" strategy="beforeInteractive">
                {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
            </Script>
        </Fragment>
    );
};

export default RewardfulHelper;
