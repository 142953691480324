import { MarketingCampaign } from "@onpreo/database";
import { createApi } from "@reduxjs/toolkit/query/react";
import { onpreoClientBaseQuery } from "../utils";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
const baseUrl = `/api/marketing-campaign`;

export const marketingCampaignApi = createApi({
    reducerPath: "marketingCampaign",
    baseQuery: onpreoClientBaseQuery({ baseUrl }),
    tagTypes: ["MarketingCampaign", "MarketingCampaignsByRange", "Chart"],
    endpoints: builder => ({
        getMarketingCampaignById: builder.query<MarketingCampaign & { createdAt: any }, void>({
            query: _id => ({ url: `/${_id}`, method: "GET" })
        }),
        getMarketingCampaigns: builder.query<MarketingCampaign[], void>({
            query: () => ({ url: "/", method: "GET" })
            // providesTags: ["MarketingCampaign"]
        }),
        getMarketingCampaignsByRange: builder.query({
            queryFn: async ({ options }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/by-range`, { options });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: ["MarketingCampaignsByRange"]
        }),
        createMarketingCampaign: builder.mutation<void, MarketingCampaign>({
            query: ({ ...campaign }) => ({ url: "/", method: "POST", data: { campaign } }),
            invalidatesTags: ["MarketingCampaign", "MarketingCampaignsByRange", "Chart"]
        }),
        createMultipleMarketingCampaigns: builder.mutation<void, { campaigns: Partial<MarketingCampaign>[] }>({
            query: ({ campaigns }) => ({ url: `/multiple`, method: "POST", data: { campaigns } }),
            invalidatesTags: ["MarketingCampaign", "MarketingCampaignsByRange", "Chart"]
        }),
        patchMarketingCampaignMetrics: builder.mutation<void, Partial<MarketingCampaign> & { _id: string }>({
            query: ({ _id, allowedMetrics }) => ({ url: `/${_id}/metrics`, method: "PATCH", data: { allowedMetrics } }),
            invalidatesTags: ["MarketingCampaign"]
        }),
        updateMarketingCampaign: builder.mutation<any, Partial<MarketingCampaign> & { _id: string }>({
            query: ({ _id, ...campaign }) => ({ url: `/${_id}`, method: "PUT", data: { campaign } }),
            invalidatesTags: ["MarketingCampaign", "MarketingCampaignsByRange", "Chart"]
        }),
        deleteMarketingCampaign: builder.mutation<void, Partial<MarketingCampaign> & { _id: string }>({
            query: ({ _id }) => ({ url: `/${_id}`, method: "DELETE" }),
            invalidatesTags: ["MarketingCampaign", "MarketingCampaignsByRange", "Chart"]
        }),
        getStatisticByQuery: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/statistic/by-query`, { searchQuery });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: ["Chart"]
        }),
        getStatisticByVariant: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/statistic/by-variant`, { searchQuery });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: ["Chart"]
        }),
        getStatisticByCampaign: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/statistic/by-campaign`, { searchQuery });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            },
            providesTags: ["Chart"]
        }),
        getMarketingCampaignNames: builder.query<{ _id: string; name: string }[], void>({
            query: () => ({ url: "/names", method: "GET" })
        })
    })
});

export const {
    useGetMarketingCampaignByIdQuery,
    useGetMarketingCampaignsQuery,
    useGetMarketingCampaignsByRangeQuery,
    useCreateMarketingCampaignMutation,
    useCreateMultipleMarketingCampaignsMutation,
    usePatchMarketingCampaignMetricsMutation,
    useUpdateMarketingCampaignMutation,
    useDeleteMarketingCampaignMutation,
    useGetStatisticByQueryQuery,
    useGetStatisticByVariantQuery,
    useGetStatisticByCampaignQuery,
    useGetMarketingCampaignNamesQuery
} = marketingCampaignApi;

export default marketingCampaignApi;
