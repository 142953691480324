import { Range } from "@onpreo/components";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { ContactsArray } from "../models/contact.state";

export const contactSlice = createSlice({
    name: "contact",
    initialState: initialReduxState.contact,
    reducers: {
        setType: (state, action: PayloadAction<(typeof initialReduxState.contact)["type"]>) => void (state.type = action.payload),
        setTotal: (state, action: PayloadAction<(typeof initialReduxState.contact)["total"]>) => void (state.total = action.payload),
        setContactsByRange: (state, action: PayloadAction<{ range: Range; contactsByRange: ContactsArray }>) => {
            // we need to sort in this users according to the range
            const [from, to] = action.payload.range;
            // make sure state.contacts is at least **to** long
            if (state.contactsByRange.length < to) {
                state.contactsByRange = state.contactsByRange.concat(Array(to - state.contactsByRange.length).fill(undefined));
            }
            // and then splice the new values into it
            // @ts-ignore
            state.contactsByRange.splice(from, to - from, ...action.payload.contactsByRange);
            // and set the active range
            state.activeRange = action.payload.range;
            state.type = "loaded";
        },
        setContactById: (state, action: PayloadAction<{ id: number | string; value: any }>) => {
            state.contactsByRange = state.contactsByRange.map(c => (c?._id === action.payload.id ? (c = { ...c, ...action.payload.value }) : c));
        },
        setActiveSort: (state, action) => {
            state.activeSort = action.payload;
        },
        setCurrentContact: (state, action: PayloadAction<typeof initialReduxState.contact.currentContact>) => {
            // @ts-ignore
            state.currentContact = action.payload;
        },
        setNetworkPartners: (state, action: PayloadAction<typeof initialReduxState.contact.networkPartners>) => {
            state.networkPartners = action.payload as [];
            return state;
        },
        setActiveQuery: (state, action: PayloadAction<typeof initialReduxState.contact.activeQuery>) => {
            state.activeQuery = { ...state.activeQuery, ...action.payload };
        },
        setContactLoadingByStep: (state, action) => {
            state.loadingByStep[action.payload.step] = action.payload.loading;
        },
        setContactsByStep: (state, action: PayloadAction<{ step?: string; data: any }>) => {
            const { step, data } = action.payload;

            if (step) {
                state.contactsBySteps[step] = state.contactsBySteps[step] ? [...state.contactsBySteps[step], ...data] : data;
            } else {
                state.contactsBySteps = data;
            }
        },
        setContactsTotalByStep: (state, action) => {
            state.totalByStep[action.payload.step] = action.payload.total;
        },
        reorderContactsInStep: (state, action: PayloadAction<{ step: string; data: any }>) => {
            state.contactsBySteps[action.payload.step] = action.payload.data;
            state.totalByStep[action.payload.step] = action.payload.data.length;
        },
        updateContactInStep: (state, action: PayloadAction<{ step: string; id: number | string; value: any }>) => {
            const newContact = { ...action.payload.value };
            state.contactsBySteps[action.payload.step] = state.contactsBySteps[action.payload.step].map(contact =>
                contact?._id === action.payload.id ? (contact = newContact) : contact
            );
        },
        setContactByCurrentStep: (state, action: PayloadAction<{ id: number | string; value: any }>) => {
            const newContact = { ...action.payload.value };
            const step = newContact?.pipelineStatus?.step;

            if (!step || !state.contactsBySteps?.[step]) return;

            const index = state.contactsBySteps[step].findIndex(c => c._id === action.payload.id);
            if (index !== -1) {
                state.contactsBySteps[step][index] = newContact;
            }
        }
    }
});

export const {
    setType,
    setTotal,
    setContactsByRange,
    setContactById,
    setActiveSort,
    setCurrentContact,
    setNetworkPartners,
    setActiveQuery,
    setContactLoadingByStep,
    setContactsByStep,
    setContactsTotalByStep,
    reorderContactsInStep,
    updateContactInStep,
    setContactByCurrentStep
} = contactSlice.actions;

export default contactSlice.reducer;
