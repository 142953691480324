import { onpreoClient } from "@onpreo/upsy-daisy/client";
import { UpdateWorkspaceIdThunk, UploadAgentImagePayload } from "../thunks/workspace.thunk";
import { AgentSettings, ImageDetails, StripeBilling } from "@onpreo/database";
import { FileDescriptor, imageHandler } from "@onpreo/toolbox/src/client";
import { replaceUmlaut } from "../../utils/formatters";

const createWorkspaceSubscription = (workspaceId: string, price: string) => {
    const url = `/api/workspace/${workspaceId}/billing/subscription`;
    return onpreoClient.post(url, { price });
};

const fetchByWorkspaceId = async (workspaceId: string) => {
    const url = `/api/workspace/${workspaceId}`;
    return await onpreoClient.get(url);
};

const updateUserWorkspace = async (workspaceId: string, billing: Partial<StripeBilling>) => {
    const url = `/api/workspace/${workspaceId}`;
    return await onpreoClient.put(url, { workspace: billing });
};

const uploadWorkspaceCompanyInfo = async ({ id, value }: any) => {
    const url = `/api/workspace/${id}/company-info`;
    return await onpreoClient.patch(url, value);
};

const createWorkspaceCustomer = async ({ workspaceId, company }: UpdateWorkspaceIdThunk) => {
    const url = `/api/workspace/${workspaceId}/billing/customer`;
    return await onpreoClient.post(url, { company });
};

const updateWorkspaceCustomer = async ({ workspaceId, company }: UpdateWorkspaceIdThunk) => {
    const url = `/api/workspace/${workspaceId}/billing/customer`;
    return await onpreoClient.patch(url, { company });
};

const uploadAgbImage = async (id: string, file: Blob, fileName?: string, type?: string) => {
    const url = `/api/workspace/${id}/agb-image`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut((file as any)?.name), mimetype: file.type };
    const response = await onpreoClient.post(url, descriptor, { params: { type } });
    await imageHandler.uploadPreSignedFile(response.data.presigned, file);
    return response;
};

const removeAgbWb = async (id: string, details: ImageDetails, type: string) => {
    const url = `/api/workspace/${id}/remove-agb-wb`;

    return await onpreoClient.delete(url, {
        data: {
            ...details,
            type
        }
    });
};

const uploadWorkspaceContactInfo = async ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/contact-info`;
    return await onpreoClient.patch(url, { ...data });
};

const uploadWorkspacePublication = async (id, value) => {
    const url = `/api/workspace/${id}/publication`;
    return await onpreoClient.patch(url, { ...value });
};

const uploadWorkspaceLandingPage = async (id, value) => {
    const url = `/api/workspace/${id}/landing-page`;
    return await onpreoClient.patch(url, { ...value });
};

const addLandingPageProofLogo = async ({ workspaceId, data }: UploadAgentImagePayload) => {
    const url = `/api/workspace/${workspaceId}/landing-page/proof-logo`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut(data?.fileName), mimetype: data.png.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, data.png);
    return response;
};

const deleteLandingPageProofLogo = async ({ workspaceId, index }: any) => {
    const url = `/api/workspace/${workspaceId}/landing-page/proof-logo/${index}`;
    return await onpreoClient.delete(url);
};

const uploadAgentImage = async ({ workspaceId, data }: UploadAgentImagePayload) => {
    const url = `/api/workspace/${workspaceId}/agent-image`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut(data?.fileName), mimetype: data.png.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, data.png);
    return response;
};

const updateAgentSettings = async (settings: AgentSettings, workspaceId: string) => {
    const url = `/api/workspace/${workspaceId}/agent-settings`;
    return await onpreoClient.post(url, settings);
};

const uploadWorkspaceContents = async ({ workspaceId, data, type }: any) => {
    const url = `/api/workspace/${workspaceId}/contents`;
    return await onpreoClient.patch(url, { data, type });
};

const uploadWorkspaceAddons = async ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/addons`;
    return await onpreoClient.patch(url, { data });
};

const addPublicationImage = async ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/publication/publication-images`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut(data?.fileName), mimetype: data.png.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, data.png);
    return response;
};

const deletePublicationImage = async ({ workspaceId, index }: any) => {
    const url = `/api/workspace/${workspaceId}/publication/publication-images/${index}`;
    return await onpreoClient.delete(url);
};

const addLandingPageRealEstates = async ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/landing-page/real-estates`;
    return await onpreoClient.patch(url, data);
};

const addLandingPageRealEstatesImage = async ({ workspaceId, reId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/landing-page/real-estates/${reId}`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut(data?.fileName), mimetype: data.png.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, data.png);
    return response;
};

const deleteLandingPageRealEstate = async ({ workspaceId, reId }: any) => {
    const url = `/api/workspace/${workspaceId}/landing-page/real-estates/${reId}`;
    return await onpreoClient.delete(url);
};

const addTeamUser = ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/team-user`;
    return onpreoClient.post(url, { ...data });
};

const removeTeamUser = ({ workspaceId, userId, value }: any) => {
    const url = `/api/workspace/${workspaceId}/team-user`;
    return onpreoClient.patch(url, { userId, value });
};

const addLogo = async ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/logo`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut(data?.fileName), mimetype: data.png.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, data.png);
    return response;
};

const removeLogo = async workspaceId => {
    const url = `/api/workspace/${workspaceId}/logo`;
    return await onpreoClient.delete(url);
};

const updateWorkspaceExtras = async ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/extras`;
    return await onpreoClient.patch(url, { data });
};

const updateWorkspaceIntegrations = async ({ workspaceId, value }: any) => {
    const url = `/api/workspace/${workspaceId}/integrations`;
    return await onpreoClient.patch(url, { ...value.integrations });
};

const updateTags = async (id, value) => {
    const url = `/api/workspace/${id}/extras`;
    return await onpreoClient.put(url, { value });
};
const updateCustomerPreferences = async (id, value) => {
    const url = `/api/workspace/${id}/customer-preferences`;
    return await onpreoClient.patch(url, { value });
};

const addBackgroundImage = async (id: string, file: Blob, fileName?: string, isSecure?: boolean) => {
    const url = `/api/workspace/${id}/add-background`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut(fileName ?? (file as any)?.name), mimetype: file.type };
    const response = await onpreoClient.post(url, { ...descriptor, isSecure });
    await imageHandler.uploadPreSignedFile(response.data.presigned, file, isSecure);
    return response;
};

const removeBackgroundImage = async (id: string, details: ImageDetails, isSecure?: boolean) => {
    const url = `/api/workspace/${id}/remove-background`;
    return await onpreoClient.delete(url, {
        data: {
            ...details,
            isSecure
        }
    });
};

const uploadContent = async (id: string, file: Blob, fileName?: string, isSecure?: boolean, contentId?: string, contentType?: string) => {
    const url = `/api/workspace/${id}/contents`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut(fileName ?? (file as any)?.name), mimetype: file.type };
    const response = await onpreoClient.post(url, { ...descriptor, isSecure, contentId, contentType });
    await imageHandler.uploadPreSignedFile(response.data.presigned, file, isSecure);
    return response;
};

const createMockups = async id => {
    const url = `/api/workspace/${id}/mockups`;
    return await onpreoClient.get(url);
};

const activateEmailTemplate = async (workspaceId, emailId, isActivated) => {
    const url = `/api/workspace/${workspaceId}/${emailId}/activate`;
    return await onpreoClient.patch(url, { isActivated });
};

const countWorkspaceValuations = async () => {
    const url = `/api/valuation`;
    return await onpreoClient.get(url);
};

const saveWorkspaceSubscriptionPrice = (workspaceId: string, price: string) => {
    const url = `/api/workspace/${workspaceId}/billing/price`;
    return onpreoClient.post(url, { price });
};

const updateWorkspaceInvitationLink = async (workspaceId: string, link: boolean) => {
    const url = `/api/workspace/${workspaceId}/select-invitation-link`;
    return await onpreoClient.patch(url, { link });
};

const uploadImageToLibrary = async (id: string, file: Blob) => {
    const url = `/api/workspace/${id}/image-library`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut((file as any)?.name), mimetype: file.type };

    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.details.presigned, file);
    return response;
};

const removeImageFromLibrary = async (id: string, removeId: string, src: string) => {
    const url = `/api/workspace/${id}/image-library`;
    const response = await onpreoClient.delete(url, {
        data: {
            src,
            removeId
        }
    });
    return response;
};

const createLocationDraft = async (id, data) => {
    const url = `/api/workspace/${id}/drafts/locations`;
    return await onpreoClient.post(url, data);
};

const deleteLocationDraft = async (id, draftId) => {
    const url = `/api/workspace/${id}/drafts/locations`;
    return await onpreoClient.delete(url, { data: draftId });
};

const uploadAgentSettingsDocs = async (id: string, file: Blob, fileName?: string) => {
    const url = `/api/workspace/${id}/agent-settings-docs`;
    const descriptor: FileDescriptor = { originalFilename: replaceUmlaut((file as any)?.name), mimetype: file.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, file);
    return response;
};

const removeAgentSettingsDocs = async (id: string, details: ImageDetails) => {
    const url = `/api/workspace/${id}/agent-settings-docs`;

    return await onpreoClient.delete(url, {
        data: {
            ...details
        }
    });
};

const updateCRMIntegrations = async (workspaceId, value) => {
    const url = `/api/workspace/${workspaceId}/integrations`;
    return await onpreoClient.post(url, { ...value });
};

const updateWorkspaceContentsResponsibleUser = async (workspaceId: string, value: any) => {
    const url = `/api/workspace/${workspaceId}/contents-responsible-user`;
    return await onpreoClient.patch(url, { value });
};

const updateGlobalAdjustmentFactor = async (workspaceId: string, globalPriceAdjustmentFactor: any) => {
    const url = `/api/workspace/${workspaceId}/global-adjustment-factor`;
    return await onpreoClient.patch(url, { globalPriceAdjustmentFactor });
};

const updateGlobalRentalAdjustmentFactor = async (workspaceId: string, globalRentalPriceAdjustmentFactor: any) => {
    const url = `/api/workspace/${workspaceId}/global-rental-adjustment-factor`;
    return await onpreoClient.patch(url, { globalRentalPriceAdjustmentFactor });
};

const deleteCRMIntegrations = async workspaceId => {
    const url = `/api/workspace/${workspaceId}/integrations`;
    return await onpreoClient.delete(url);
};

const updateStepMailInMailCenter = async (workspaceId, value) => {
    const url = `/api/workspace/${workspaceId}/mail-center`;
    return await onpreoClient.put(url, value);
};

const addStepMailsByMultipleTagsInMailCenter = async (workspaceId, value) => {
    const url = `/api/workspace/${workspaceId}/mail-center/multiple-tags`;
    return await onpreoClient.put(url, value);
};

const updateStepMailsByMultipleTagsInMailCenter = async (workspaceId, value, connectedId) => {
    const url = `/api/workspace/${workspaceId}/mail-center/multiple-tags/${connectedId}`;
    return await onpreoClient.put(url, value);
};

const deleteMailFromMailCenter = async (workspaceId, { step, tag, hasDefault }) => {
    const url = `/api/workspace/${workspaceId}/mail-center`;
    return await onpreoClient.patch(url, { step, tag, hasDefault });
};

const deleteMailFromMailCenterWithMultipleTags = async (workspaceId, { step, currentValues, connectedId }) => {
    const url = `/api/workspace/${workspaceId}/mail-center/multiple-tags/${connectedId}`;
    return await onpreoClient.patch(url, { step, currentValues, connectedId });
};

const updateUseDefaultInMailCenter = async (workspaceId, { step, tag, useDefault }) => {
    const url = `/api/workspace/${workspaceId}/mail-center/update-default`;
    return await onpreoClient.patch(url, { step, tag, useDefault });
};

const updateStepActivityInActivityCenter = async (workspaceId, value) => {
    const url = `/api/workspace/${workspaceId}/activity-center`;
    return await onpreoClient.put(url, value);
};

const updateDefaultIdsInActivityCenter = async (workspaceId, { step, defaultId, value }) => {
    const url = `/api/workspace/${workspaceId}/activity-center/update-default`;
    return await onpreoClient.patch(url, { step, defaultId, value });
};

const deleteActivityTemplateFromActivityCenter = async (workspaceId, { step, templateId }) => {
    const url = `/api/workspace/${workspaceId}/activity-center`;
    return await onpreoClient.patch(url, { step, templateId });
};

const updateBackground = async (id, value) => {
    const url = `/api/workspace/${id}/background`;
    return await onpreoClient.put(url, { value });
};

const updateGeneralCustomServices = async (payload: any) => {
    const url = `/api/workspace/general-custom-services`;
    return await onpreoClient.put(url, { payload });
};

const updateGlobalSegmentationMails = async (workspaceId, isActivated) => {
    const url = `/api/workspace/${workspaceId}/global-segmentation-mails`;
    return await onpreoClient.patch(url, { isActivated });
};

const updateVisibilityInPipelineCenter = async (workspaceId, pipeline) => {
    const url = `/api/workspace/${workspaceId}/pipeline-center`;
    return await onpreoClient.patch(url, { pipeline });
};

const updateRealEstateLPsRedirect = async (workspaceId, redirect) => {
    const url = `/api/workspace/${workspaceId}/landing-page/redirect`;
    return await onpreoClient.patch(url, { redirect });
};

const updateWorkspaceIntegrationsExportSetting = async (value, variant) => {
    const url = `/api/workspace/integrations/setting`;
    return await onpreoClient.patch(url, { value, variant });
};

const updateWorkspaceLeadConnect = async value => {
    const url = `/api/workspace/lead-connect`;
    return await onpreoClient.patch(url, { value });
};

const deleteWorkspaceRealEstatesContacts = async (id: string) => {
    const url = `/api/workspace/${id}/clean`;
    return await onpreoClient.delete(url);
};

const updateWorkspaceMMCSettings = async (workspaceId, mmcSettings) => {
    const url = `/api/workspace/${workspaceId}/mmc-settings`;
    return await onpreoClient.patch(url, mmcSettings);
};

const deleteMMCSender = async workspaceId => {
    const url = `/api/workspace/${workspaceId}/mmc-settings`;
    return await onpreoClient.delete(url);
};

const deleteReplyToEmail = async workspaceId => {
    const url = `/api/workspace/${workspaceId}/mmc-settings/reply-to`;
    return await onpreoClient.delete(url);
};

const sendSenderActivationLink = async (workspaceId, { link, senderMail }) => {
    const url = `/api/workspace/${workspaceId}/mmc-settings`;
    return await onpreoClient.post(url, { link, senderMail });
};

const updateSenderStatus = async (workspaceId, senderMail) => {
    const url = `/api/workspace/${workspaceId}/mmc-settings/sender-status`;
    return await onpreoClient.patch(url, { senderMail });
};

const updateWorkspaceContentsPipeline = async (workspaceId: string, data: any) => {
    const url = `/api/workspace/${workspaceId}/contents-pipeline`;
    return await onpreoClient.patch(url, { data });
};

const resetWorkspaceContentsPipeline = async (workspaceId: string) => {
    const url = `/api/workspace/${workspaceId}/contents-pipeline`;
    return await onpreoClient.put(url);
};

const updateWorkspaceContentsZipFilter = async (workspaceId: string, data: any) => {
    const url = `/api/workspace/${workspaceId}/zip-filter`;
    return await onpreoClient.patch(url, { data });
};

const updateIntegrationsContactList = async (listId: string) => {
    const url = `/api/workspace/integrations/contact-list`;
    return await onpreoClient.patch(url, { listId });
};

const workspaceService = {
    createWorkspaceSubscription,
    fetchByWorkspaceId,
    updateWorkspaceBilling: updateUserWorkspace,
    createWorkspaceCustomer,
    updateWorkspaceCustomer,
    uploadWorkspaceCompanyInfo,
    uploadWorkspaceContactInfo,
    uploadWorkspacePublication,
    uploadWorkspaceLandingPage,
    addLandingPageProofLogo,
    deleteLandingPageProofLogo,
    uploadAgentImage,
    updateAgentSettings,
    uploadWorkspaceContents,
    uploadWorkspaceAddons,
    addPublicationImage,
    deletePublicationImage,
    addLandingPageRealEstates,
    addLandingPageRealEstatesImage,
    deleteLandingPageRealEstate,
    addTeamUser,
    removeTeamUser,
    addLogo,
    removeLogo,
    updateWorkspaceExtras,
    updateWorkspaceIntegrations,
    uploadAgbImage,
    removeAgbWb,
    updateTags,
    updateCustomerPreferences,
    addBackgroundImage,
    removeBackgroundImage,
    uploadContent,
    createMockups,
    activateEmailTemplate,
    countWorkspaceValuations,
    saveWorkspaceSubscriptionPrice,
    updateWorkspaceInvitationLink,
    uploadImageToLibrary,
    removeImageFromLibrary,
    createLocationDraft,
    deleteLocationDraft,
    uploadAgentSettingsDocs,
    removeAgentSettingsDocs,
    updateCRMIntegrations,
    updateWorkspaceContentsResponsibleUser,
    updateGlobalAdjustmentFactor,
    updateGlobalRentalAdjustmentFactor,
    deleteCRMIntegrations,
    updateStepMailInMailCenter,
    addStepMailsByMultipleTagsInMailCenter,
    updateStepMailsByMultipleTagsInMailCenter,
    deleteMailFromMailCenter,
    deleteMailFromMailCenterWithMultipleTags,
    updateUseDefaultInMailCenter,
    updateStepActivityInActivityCenter,
    updateDefaultIdsInActivityCenter,
    deleteActivityTemplateFromActivityCenter,
    updateBackground,
    updateGeneralCustomServices,
    updateGlobalSegmentationMails,
    updateVisibilityInPipelineCenter,
    updateRealEstateLPsRedirect,
    updateWorkspaceIntegrationsExportSetting,
    updateWorkspaceLeadConnect,
    deleteWorkspaceRealEstatesContacts,
    updateWorkspaceMMCSettings,
    deleteMMCSender,
    deleteReplyToEmail,
    updateSenderStatus,
    sendSenderActivationLink,
    updateWorkspaceContentsPipeline,
    resetWorkspaceContentsPipeline,
    updateWorkspaceContentsZipFilter,
    updateIntegrationsContactList
};

export default workspaceService;
