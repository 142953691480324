import { createApi } from "@reduxjs/toolkit/query/react";
import { onpreoClientBaseQuery } from "../utils";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
const baseUrl = `/api/sales-statistics`;

export const salesStatisticsApi = createApi({
    reducerPath: "salesStatistics",
    baseQuery: onpreoClientBaseQuery({ baseUrl }),
    tagTypes: [""],
    endpoints: builder => ({
        getStatisticsByPipelineSteps: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/by-pipeline`, { searchQuery });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getStatisticsForFunnel: builder.query({
            queryFn: async ({ pipeline, searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}`, { pipeline, searchQuery });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getStatisticsByStep: builder.query({
            queryFn: async ({ searchQuery, isLost }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/by-step`, { searchQuery, isLost });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getSpecificLeadStatistics: builder.query({
            queryFn: async ({ realEstateIds }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/by-leads`, { realEstateIds });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getLeadsFromSettingToClosingByDate: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/general/leads-from-setting-to-closing`, {
                        searchQuery
                    });
                    return result.data;
                } catch (error) {
                    return { error };
                }
            }
        }),
        getNewLeadsStatistics: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/general/new-leads`, {
                        searchQuery
                    });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getLostReasonStatistics: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/general/lost-reason`, {
                        searchQuery
                    });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getWonLeadsStatistics: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/general/won-leads`, {
                        searchQuery
                    });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getLeadsByTeamUsers: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/general/by-team-users`, {
                        searchQuery
                    });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getLeadsByTags: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/general/by-tags`, {
                        searchQuery
                    });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        })
    })
});

export const {
    useGetStatisticsByPipelineStepsQuery,
    useGetStatisticsForFunnelQuery,
    useGetStatisticsByStepQuery,
    useGetSpecificLeadStatisticsQuery,
    useGetLeadsFromSettingToClosingByDateQuery,
    useGetNewLeadsStatisticsQuery,
    useGetLostReasonStatisticsQuery,
    useGetWonLeadsStatisticsQuery,
    useGetLeadsByTeamUsersQuery,
    useGetLeadsByTagsQuery
} = salesStatisticsApi;

export default salesStatisticsApi;
